import { speed } from 'Lib/speed/speed';
import { isTouch } from 'Lib/istouch/istouch';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import { getW } from 'Lib/w/w';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $body = $('body');
const $linkSubMenuA = $('.menu2__item_submenu .menu2__link');
const $linkSubMenu = $linkSubMenuA.parent();
const cLinkSubMenuHovered = '__item_submenu-catalog-hovered';
const cLinkSubMenuCatalog = 'menu2__item_submenu-catalog';
const cLinkSubMenuCatalogActive = 'menu2__item_submenu-catalog-active';
const $subMenu = $('.menu2__submenu');
const cSubMenuActive = 'menu2__submenu_active';
const cSubMenuHovered = 'menu2__submenu_hovered';
const $catalogPanel = $('.menu2__catalog-panel');
const href = $catalogPanel.data('href');
const cCatalogLoaded = 'menu2__catalog-panel_loaded';
const cCatalogLoadedContent = 'menu2__catalog-panel_loaded-content';
const cBodyCatalogMenuActive = 'menu2__body-catalog-active';
const sCloseCatalogBodyClassLink = '.menu2__close-catalog-link';
const sCatalogScroll = '.menu2__catalog-scroll';
const offsetForSetHeight = 60;

function getObjTouchState() {
    const $closeCatalogBodyClassLink = $(sCloseCatalogBodyClassLink);
    let closeCatalogLinkHeight = 0;
    const $header = $('.header__for-fix');
    const cHeaderFixed = 'header__for-fix_fixed';
    const $headerBottom = $('.header__bottom');
    let headerHeight = $header.outerHeight();
    const catalogPanelHeight = $catalogPanel.height();

    if ($header.hasClass(cHeaderFixed)) {
        headerHeight = $headerBottom.outerHeight();
    }

    if ($closeCatalogBodyClassLink.length) {
        closeCatalogLinkHeight = 44;
    }

    const resultHeight = $(window).outerHeight(true) - headerHeight - closeCatalogLinkHeight;

    if (isTouch() || (catalogPanelHeight + offsetForSetHeight) > resultHeight) {
        return { state: true, height: resultHeight };
    }

    return { state: false, height: resultHeight };
}

function showCatalogBodyClass() {
    const objState = getObjTouchState();
    const scrollLineWidth = parseInt(getW()) - parseInt(document.documentElement.clientWidth);
    const $header = $('.header__for-fix_fixed');
    const $catalogScroll = $(sCatalogScroll);

    if (objState.state) {
        $body.addClass(cBodyCatalogMenuActive).css({ 'margin-right': scrollLineWidth });
        $header.css({ 'padding-right': scrollLineWidth });
        $catalogScroll.css({ 'margin-right': -scrollLineWidth });
    }
}

function closeCatalogBodyClass() {
    const $header = $('.header__for-fix_fixed');
    const $catalogScroll = $(sCatalogScroll);

    $body.removeClass(cBodyCatalogMenuActive).css({ 'margin-right': 0 });
    $header.css({ 'padding-right': 0 });
    $catalogScroll.removeAttr('style');
}

function setHeightCatalogMenu() {
    const $catalogScroll = $(sCatalogScroll);

    if ($catalogScroll.length) {
        const objState = getObjTouchState();

        if (objState.state) {
            $catalogScroll.scrollTop(0).css({ height: objState.height });
        }
    }
}

function setCatalogMenu() {
    if (!$catalogPanel.hasClass(cCatalogLoaded) && isNoEmptyString(href)) {
        $.ajax({
            url: href,
            cache: false,
            success(data) {
                $catalogPanel.html(data);
                $catalogPanel.addClass(cCatalogLoadedContent);
                setHeightCatalogMenu();
                showCatalogBodyClass();
            },
        });

        $catalogPanel.addClass(cCatalogLoaded);
    } else {
        setHeightCatalogMenu();
        showCatalogBodyClass();
    }
}

if ($('.menu2').length) {
    $linkSubMenu.on('mouseenter', function () {
        if (!isTouch()) {
            $(this).addClass(cLinkSubMenuHovered);
        }
    }).on('mouseleave', function () {
        if (!isTouch()) {
            $(this).removeClass(cLinkSubMenuHovered);
        }
    });

    $linkSubMenu.on('mouseenter', $.debounce(speed * 1.5, function () {
        if (!isTouch()) {
            const $this = $(this);

            if ($this.hasClass(cLinkSubMenuHovered)) {
                $subMenu.removeClass(cSubMenuActive);
                closeCatalogBodyClass();
                $this.find($subMenu).addClass(cSubMenuActive);

                if ($this.hasClass(cLinkSubMenuCatalog)) {
                    setCatalogMenu();
                }
            }
        }
    })).on('mouseleave', $.debounce(speed * 1.5, function () {
        const $curSubMenu = $(this).find($subMenu);

        if (!isTouch() && !$curSubMenu.hasClass(cSubMenuHovered)) {
            $curSubMenu.removeClass(cSubMenuActive);
            closeCatalogBodyClass();
        }
    })).on('click', function (e) {
        if (isTouch()) {
            const $this = $(this);
            const $curSubMenu = $this.find($subMenu);

            if (!$curSubMenu.hasClass(cSubMenuActive)) {
                $subMenu.removeClass(cSubMenuActive);
                $linkSubMenu.removeClass(cLinkSubMenuCatalogActive);
                closeCatalogBodyClass();
                $curSubMenu.addClass(cSubMenuActive);

                if ($this.hasClass(cLinkSubMenuCatalog)) {
                    $this.addClass(cLinkSubMenuCatalogActive);
                    setCatalogMenu();
                }
            } else if (!$(e.target).closest($subMenu).length && !$(e.target).is($subMenu)) {
                $curSubMenu.removeClass(cSubMenuActive);
                $this.removeClass(cLinkSubMenuCatalogActive);
                closeCatalogBodyClass();
            }
        }
    });

    $(document).click((e) => {
        if (isTouch() && $subMenu.filter(`.${cSubMenuActive}`).length) {
            if ($(e.target).closest($linkSubMenu).length || $(e.target).is($linkSubMenu)) {
                return;
            }

            $subMenu.removeClass(cSubMenuActive);
            $linkSubMenu.removeClass(cLinkSubMenuCatalogActive);
            closeCatalogBodyClass();
            e.stopPropagation();
        }
    });

    $linkSubMenuA.on('click', (e) => {
        if (isTouch()) {
            e.preventDefault();
        }
    });

    $body.on('click', sCloseCatalogBodyClassLink, () => {
        $subMenu.removeClass(cSubMenuActive);
        $linkSubMenu.removeClass(cLinkSubMenuCatalogActive);
        closeCatalogBodyClass();
    });

    $(window).on('scroll resize', () => {
        setHeightCatalogMenu();
        setTimeout(setHeightCatalogMenu, speed * 2 + 10);
    });

    $subMenu.on('mouseenter', function () {
        $(this).addClass(cSubMenuHovered);
    }).on('mouseleave', function () {
        $(this).removeClass(cSubMenuHovered);
    });
}