import { speed } from 'Lib/speed/speed';

const sAcc = '.accordion';
const $acc = $(sAcc);
const sLink = '.accordion__link';
const sCont = '.accordion__cont';
const cJscont = 'accordion__jscont';
const sJscont = `.${cJscont}`;
const cLoaded = 'accordion_loaded';
const cActive = 'accordion_active';
const cProcess = 'accordion_process';
const cOnlyOpen = 'accordion_only-open';

function showAcc($a) {
    $a.each(function () {
        const thisA = $(this);
        const $cont = thisA.find(sCont);
        const $jsCont = thisA.find(sJscont);

        if ($cont.length && $jsCont.length && !thisA.hasClass(cProcess) && !thisA.hasClass(cActive)) {
            const h = $cont.outerHeight(true);

            thisA.addClass(cProcess);
            $jsCont.height(h);

            if (thisA.hasClass(cOnlyOpen)) {
                hideAcc(thisA.siblings(`.${cOnlyOpen}`));
            }

            setTimeout(() => {
                thisA.removeClass(cProcess);
                thisA.addClass(cActive);
                $jsCont.removeAttr('style');
            }, speed);
        }
    });
}

function hideAcc($a) {
    $a.each(function () {
        const thisA = $(this);
        const $cont = thisA.find(sCont);
        const $jsCont = thisA.find(sJscont);

        if ($cont.length && $jsCont.length && !thisA.hasClass(cProcess) && thisA.hasClass(cActive)) {
            const h = $cont.outerHeight(true);

            thisA.addClass(cProcess);
            $jsCont.height(h);
            $jsCont.height(0);

            setTimeout(() => {
                thisA.removeClass(cActive);
                thisA.removeClass(cProcess);
                $jsCont.removeAttr('style');
            }, speed);
        }
    });
}

$acc.each(function () {
    const $this = $(this);
    const $cont = $this.find(sCont);

    if ($cont.length) {
        $cont.wrap(`<div class="${cJscont}"></div>`);
        $this.addClass(cLoaded);
    }
});

$acc.on('click', sLink, function (e) {
    const $parent = $(this).closest(sAcc);

    e.preventDefault();

    if ($parent.length) {
        if (!$parent.hasClass(cActive)) {
            showAcc($parent);
        } else {
            hideAcc($parent);
        }
    }
});