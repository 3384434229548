import 'jquery';
import 'element-closest-polyfill';
import 'custom-event-polyfill';
import 'whatwg-fetch';
import 'Bem/site/cmenu/cmenu';
import 'Lib/on-error/on-error';
import 'Vendor/fancybox/jquery.fancybox.min';
import 'Lib/fancybox-custom/fancybox-custom';
import 'Lib/font-load/font-load';
import 'Lib/touch-retina-to-html-tag/touch-retina-to-html-tag';
import 'Lib/auth/auth';
import 'Lib/vkontakte/vkontakte';
import 'Lib/es-page-open/es-page-open';
import 'Lib/on-width-resize/on-width-resize';
import { whiteLabel } from 'Lib/white-label/white-label';
import 'Lib/bitrix-events/bitrix-events';
import { oSelectbox } from 'Lib/selectbox/selectbox';
import 'Lib/on-window-loaded/on-window-loaded';
import 'Bem/site/ajs/ajs';
import 'Bem/site/auth/auth';
import 'Bem/site/menu2/menu2';
import 'Bem/site/m-menu/m-menu';
import 'Bem/site/footer/footer';
import 'Bem/site/ajax-form/ajax-form';
import 'Bem/site/search-content/search-content';
import 'Bem/site/pagenav-ajax/pagenav-ajax';
import 'Bem/site/accordion/accordion';
import 'Bem/site/form/form';
import 'Bem/site/auth-form/auth-form';
import 'Bem/site/faq-bot/faq-bot';
import 'Bem/site/helper/helper';
import 'Bem/site/link-drop-down/link-drop-down';
import 'Bem/site/mail-form/mail-form';
import 'Bem/site/popup-unified/popup-unified';
import 'Bem/site/cookie-accept/cookie-accept';
import 'Bem/site/banner-top/banner-top';
import 'Bem/site/menu-left/menu-left';
import 'Bem/site/csearch/csearch';
import 'Bem/site/cheader/cheader';
import 'Bem/site/switch-language/switch-language';
import 'Lib/set-webpack-public-path/set-webpack-public-path';
import 'Bem/site/arrow-up/arrow-up';
import 'Lib/js-user-exit/js-user-exit';
import 'Bem/site/popup18/popup18';
import 'Bem/site/checkbox-accept/checkbox-accept';
import 'Bem/site/skeleton-load/skeleton-load';
import 'Bem/site/cart/cart';
import 'Bem/site/version-visually-impaired/version-visually-impaired';

oSelectbox.init('.selectbox');
window.whiteLabel = whiteLabel;